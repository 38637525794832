import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const FeaturesPage = () => {
  const { id } = useParams();
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetch(`https://leadsproecommerceleadstool-c0d6f532c90f.herokuapp.com/features`)
      .then((res) => res.json())
      .then((info) => setServices(info));
  }, [id]);

  return (
    <>
      {/* <div className="software-feature-area default-padding">
        <div className="container">
          <div className="feature-items">
            <div className="row align-center">
              <div className="col-lg-6 left-info">
                <h2>{feature.featureTitle}</h2>
                <p>{feature.featureDesc}</p>
                <Link
                  className="btn circle btn-theme-effect btn-sm"
                  to={feature.btnUrl}
                >
                  {feature.btnText}
                </Link>
              </div>
              <div className="col-lg-6">
                <div className="features-list">
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-angle-right" />
                      <img src={feature.ImageBoxOne} alt="Icon" />
                    </div>
                    <div className="content">
                      <h4>{feature.TitleBoxOne}</h4>
                      <p>{feature.ParaBoxOne}</p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-angle-right" />

                      <img src={feature.ImageBoxTwo} alt="Icon" />
                    </div>
                    <div className="content">
                      <h4>{feature.TitleBoxTwo}</h4>
                      <p>{feature.ParaBoxTwo}</p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-angle-right" />
                      <img src={feature.ImageBoxThree} alt="Icon" />
                    </div>
                    <div className="content">
                      <h4>{feature.TitleBoxThree}</h4>
                      <p>{feature.ParaBoxThree}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="home-1_service-section padding-bottom-120" id="feature">
        <div className="home-1_service-section-shape">
          <img src="./image/home-1/service-section-shape.svg" alt="image alt" />
        </div>
        <div className="container">
          <div className="home-1_service-section-wrapper">
            <div className="row justify-content-center">
              <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
                <div className="section-heading text-center">
                  <h2 className="section-heading__title heading-md text-black">
                  eCommerce leads enhance targeted marketing efforts
                  </h2>
                </div>
              </div>
            </div>
            <div className="row gutter-y-default justify-content-center">
              {
                services.map((e, index) =>
                  <div className="col-xl-6 col-lg-6 col-md-10">
                    <Link
                     
                      className="service-card hvr-fill"
                      data-aos-duration={1000 * index}
                      data-aos="fade-right"
                    >
                      <div className="service-card__icon">
                        <img
                          src={e.featureImg}
                          alt={e.featureTitle}
                          className="inline-svg"
                        />
                      </div>
                      <div className="service-card__body">
                        <h3 className="service-card__title">
                          {e.featureTitle}
                        </h3>
                        <p>
                          {e.featureDesc}
                        </p>
                      </div>
                    </Link>
                  </div>
                )
              }

            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default FeaturesPage;
