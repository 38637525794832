import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../firebase.init";

const ReceivedPayment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const [profile, setProfile] = useState([]);
  const [order, setOrder] = useState([]);

  // Helper function to find the correct profile to update
  const getProfileToUpdate = () => {
    return profile.find((e) => e.userEmail === user?.email) || {};
  };

  useEffect(() => {
    // Fetch order details
    fetch(`https://leadsproecommerceleadstool-c0d6f532c90f.herokuapp.com/order/${id}`)
      .then((res) => res.json())
      .then((info) => setOrder(info));

    // Fetch user profile details
    fetch(`https://leadsproecommerceleadstool-c0d6f532c90f.herokuapp.com/profiles`)
      .then((res) => res.json())
      .then((info) => {
        const updatedProfiles = info.map((profile) =>
          profile.userEmail === user?.email
            ? { ...profile, order: order }
            : profile
        );
        setProfile(updatedProfiles);
      });
  }, [id, user, order]);

  const handleUpdate = (event) => {
    event.preventDefault();
    const paymentStatus = event.target.paymentStatus.value;

    // Calculate total credits from the purchased package
    const totalCredits = parseFloat(order.totalCredits) || 0;

    // Retrieve user's current userPoint
    const currentUserPoint = parseFloat(getProfileToUpdate().userPoint) || 0;

    // Add total credits to userPoint
    const updatedUserPoint = currentUserPoint + totalCredits;

    // Update userPoint in the user's profile
    const updatedProfile = profile.map((e) =>
      e.userEmail === user?.email ? { ...e, userPoint: updatedUserPoint } : e
    );

    // Update user profile
    fetch(`https://leadsproecommerceleadstool-c0d6f532c90f.herokuapp.com/update-credit/${getProfileToUpdate()._id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ userPoint: updatedUserPoint.toString() }),
    })
      .then((res) => res.json())
      .then(() => {
        // Update payment status
        fetch(`https://leadsproecommerceleadstool-c0d6f532c90f.herokuapp.com/payment-received/${order._id}`, {
          method: "PUT",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ paymentStatus }),
        })
          .then((res) => res.json())
          .then(() => {
            navigate("/user-dashboard");
          });
      });
  };

  return (
    <section className="py-5">
      <div className="container">
        <div className="row vh-100 justify-content-center align-items-center">
          <div className="col-lg-8">
            <div className="card p-4 shadow-sm border-0" style={{ backgroundColor: 'rgb(250, 249, 245)' }}>
              <h2 className="text-center mb-4">Payment Confirmation</h2>
              <form id="receivedPaymentForm" onSubmit={handleUpdate}>
                <input
                  type="hidden"
                  name="paymentStatus"
                  value="Received"
                />
                {profile.map(
                  (e) =>
                    e.userEmail === user?.email && (
                      <input
                        key={e._id}
                        type="hidden"
                        name="TotalUserPoint"
                        value={Math.floor(e.userPoint)}
                        readOnly
                      />
                    )
                )}
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                  >
                    Confirmed and Add the Credit to the Balance
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReceivedPayment;
