import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import auth from "../../firebase.init";
import { useAuthState } from "react-firebase-hooks/auth";
import PageHero from "./PageHero";

const NavBar = () => {
  const [logo, setLogo] = useState({});
  const [user] = useAuthState(auth);
  const [admin, setAdmin] = useState([]);
  const location = useLocation();

  const handleSignout = () => {
    signOut(auth);
  };

  useEffect(() => {
    fetch(`https://leadsproecommerceleadstool-c0d6f532c90f.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info[0]));
  }, []);
  useEffect(() => {
    fetch(`https://leadsproecommerceleadstool-c0d6f532c90f.herokuapp.com/users`)
      .then((res) => res.json())
      .then((info) => setAdmin(info));
  }, []);
  const isAdmin = user && admin.some((adm) => adm.userEmail === user.email);
  const isHomePage = location.pathname === "/";
  const shouldRenderPageHero = !isHomePage;

  return (
    <>


      <header className="site-header site-header--transparent  bg--primary">
        <div className="container">
          <nav className="navbar site-navbar">

            <div className="brand-logo">
              <Link to="/">

                <img className="logo-light" src={logo.logo} alt="brand logo" />
                {/* Dark version logo (logo must be White)*/}
                <img
                  className="logo-dark"
                  src={logo.logo}
                  alt="brand logo"
                />
              </Link>
            </div>
            <div className="menu-block-wrapper">
              <div className="menu-overlay" />
              <nav className="menu-block" id="append-menu-header">
                <div className="mobile-menu-head">
                  <Link to="/">
                    <img src={logo.logo} alt="brand logo" />
                  </Link>
                  <div className="current-menu-title" />
                  <div className="mobile-menu-close">×</div>
                </div>
                <ul className="site-menu-main">

                  <li className="nav-item">
                    <Link to="/" className="nav-link-item drop-trigger">
                      Home
                    </Link>
                    <Link to="/pricing" className="nav-link-item drop-trigger">
                      Pricing
                    </Link>
                    <Link to="/about-us" className="nav-link-item drop-trigger">
                      About us
                    </Link>
                    <Link to="/contact" className="nav-link-item drop-trigger">
                      Contact us
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            {user ? (
              <>
                <Link
                  to="/user-dashboard"
                  className="btn-masco btn-masco--header rounded-pill btn-fill--up"
                >
                  <span>Dashboard</span>
                </Link>
                {isAdmin && (
                  <Link
                    to="/admin/dashboard"
                    className="btn-masco btn-masco--header rounded-pill btn-fill--up"
                  >
                    <span>Admin</span>
                  </Link>
                )}
              </>
            ) : (
              <Link
                to="/login"
                className="btn-masco btn-masco--header rounded-pill btn-fill--up"
              >
                <span>Login</span>
              </Link>
            )}


            <div className="mobile-menu-trigger">
              <span />
            </div>


          </nav>
        </div>
      </header>

      {shouldRenderPageHero && <PageHero />}
    </>
  );
};

export default NavBar;
